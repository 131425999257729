require("axios");
require("vue");

import { createApp } from 'vue';
import axios from 'axios';

// js call method
function top_news_blocks_api_process(tgt_dom_id){

    const Worker = {
        data() {
            return {
                info: {},
                loading: true,
                errored: false,
            }
        },
        methods: {
            call_api: function(time){
                const tgt_url = '/api/top_news_blocks.json';

                var vueObj = this;
                axios
                    .get(tgt_url)
                    .then(response => {
                        vueObj.info = response.data;
                        // console.log('loaded!');
                    })
                    .catch(error => {
                        console.log(error)
                        vueObj.errored = true;
                    })
                    .finally(() => vueObj.loading = false);
            }
        },
        computed: {
        },
        mounted: function(){
            // console.log('loading...');
            var vueObj = this;
            vueObj.call_api(null);
        }
    };
    const app = createApp(Worker).mount(tgt_dom_id);
}

// ここが有効になればグローバルに使えるようになる。
window.top_news_blocks_api_process = top_news_blocks_api_process;
